
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useGettext } from "vue3-gettext";
import { dynStr } from "@/core/services/DynamicTranslationService";

export default defineComponent({
  name: "kt-widget-14",
  components: {
    //Dropdown2
  },
  props: {
    widgetClasses: String,
  },
  data() {
    const store = useStore();
    let ws3;
    const gettext = useGettext();
    const checkedPackets: { packetId: string | null }[] = [];
    return {
      checkedPackets,
      ws3,
      store,
      dynStr,
      gettext,
    };
  },
  methods: {
    printAllButtonClick: function (e: Event) {
      if (e == null || e.target == null) return;
      if (this.checkedPackets.length < 1) return;
      const packetIds: string[] = [];
      for (const pac of this.checkedPackets) {
        if (pac["packetId"] == null) continue;
        const packet =
          this.store.state.PacketsModule.orderList.printeePackets.find(
            (i) => i.packetId == pac["packetId"]
          );
        if (packet.s == 3 || packet.s == 4) {
          packetIds.push(pac["packetId"]);
        }
      }
      if (packetIds.length < 1) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      const data = {
        packetIds,
      };
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");
      this.store
        .dispatch(Actions.LABELS, data)
        .then((response) => {
          submitButton.removeAttribute("data-kt-indicator");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          link.click();
          submitButton.removeAttribute("disabled");
        })
        .catch(() => {
          submitButton.removeAttribute("data-kt-indicator");
          console.log(this.store.getters.getErrors);
          Swal.fire({
            text: this.store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButton.removeAttribute("disabled");
        });
    },
    acceptAllButtonClick: function (e: Event) {
      if (e == null || e.target == null) return;
      if (this.checkedPackets.length < 1) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      if (submitButton.getAttribute("disabled")) return;
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");
      for (const pac of this.checkedPackets) {
        if (pac["packetId"] == null) continue;
        console.log(pac);
        const packet =
          this.store.state.PacketsModule.orderList.printeePackets.find(
            (i) => i.packetId == pac["packetId"]
          );
        let data:
          | { translation: string; packetId: string }
          | { packetIds: string[] } = {
          translation: this.gettext.current ?? "en",
          packetId: pac["packetId"],
        };
        if (packet.s === 2) {
          submitButton.setAttribute("data-kt-indicator", "on");
          submitButton.setAttribute("disabled", "true");
          this.store
            .dispatch(Actions.ACCEPT, data)
            .then(() => {
              this.store
                .dispatch(Actions.DOWNLOAD, data)
                .then((response) => {
                  submitButton.removeAttribute("data-kt-indicator");
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  const splited =
                    response.headers["content-disposition"].split("=");
                  link.download = decodeURIComponent(
                    splited[splited.length - 1].replaceAll('"', "")
                  );
                  response.config.url.substr(
                    response.config.url.lastIndexOf("/") + 1
                  ) + ".zip";
                  link.click();
                  submitButton.removeAttribute("disabled");
                })
                .catch(() => {
                  submitButton.removeAttribute("data-kt-indicator");
                  Swal.fire({
                    text: this.store.getters.getErrors,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: this.$gettext("Try again!"),
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                  submitButton.removeAttribute("disabled");
                });
            })
            .catch(() => {
              submitButton.removeAttribute("data-kt-indicator");
              Swal.fire({
                text:
                  this.store.getters.getErrors === "generic"
                    ? "Contact support via email"
                    : this.store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: this.$gettext("Contact support"),
                cancelButtonText: this.$gettext("Cancel"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                  cancelButton: "btn fw-bold btn-light-danger",
                },
              }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                  document.location.href =
                    "mailto:support@printeepro.com?subject=" +
                    encodeURIComponent(`WEBSPS - ${pac["packetId"]} - ERROR`) +
                    "&body=" +
                    encodeURIComponent(
                      `ERROR has occurred when calling packet ${pac["packetId"]}.\n\n<i>For easier error debuging, please write steps to replicate error below.</i>`
                    );
                }
              });
              submitButton.removeAttribute("disabled");
            });
        } else if (packet.s === 3 || packet.s === 4) {
          submitButton.setAttribute("data-kt-indicator", "on");
          submitButton.setAttribute("disabled", "true");
          const token = getToken(ID_TOKEN_KEY);
          if (token == null || typeof token != "string") return;

          data = {
            packetIds: [pac["packetId"]],
          };

          this.store
            .dispatch(Actions.LABELS, data)
            .then((response) => {
              submitButton.removeAttribute("data-kt-indicator");
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              const splited =
                response.headers["content-disposition"].split("=");
              link.download = decodeURIComponent(
                splited[splited.length - 1].replaceAll('"', "")
              );
              link.click();
              submitButton.removeAttribute("disabled");
            })
            .catch(() => {
              submitButton.removeAttribute("data-kt-indicator");
              console.log(this.store.getters.getErrors);
              Swal.fire({
                text: this.store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: this.$gettext("Try again!"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton.removeAttribute("disabled");
            });
        }
      }
      submitButton.removeAttribute("data-kt-indicator");
      submitButton.removeAttribute("disabled");
    },
    downloadAllButtonClick: function (e: Event) {
      if (e == null || e.target == null) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      if (this.checkedPackets.length < 1) return;
      const packetIds: string[] = [];
      for (const pac of this.checkedPackets) {
        if (pac["packetId"] == null) continue;
        console.log(pac);
        const packet =
          this.store.state.PacketsModule.orderList.printeePackets.find(
            (i) => i.packetId == pac["packetId"]
          );
        if (packet.s == 3) {
          packetIds.push(pac["packetId"]);
        }
      }
      if (packetIds.length < 1) return;
      const data = {
        translation: this.gettext.current ?? "en",
        packetIds: JSON.stringify(packetIds),
      };
      console.log(data);
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");
      this.store
        .dispatch(Actions.DOWNLOAD, data)
        .then((response) => {
          submitButton.removeAttribute("data-kt-indicator");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          response.config.url.substr(response.config.url.lastIndexOf("/") + 1) +
            ".zip";
          link.click();
          submitButton.removeAttribute("disabled");
        })
        .catch(() => {
          submitButton.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: this.store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButton.removeAttribute("disabled");
        });
    },
    downloadButtonClick: function (e: Event, { packetId, s }) {
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      const submitIcon = (
        (e.target as HTMLElement).tagName == "I"
          ? e.target
          : (e.target as HTMLElement).firstChild
      ) as HTMLElement;
      const data = {
        lang: this.gettext.current ?? "en",
        packetIds: JSON.stringify([packetId]),
      };
      const classData = submitIcon.getAttribute("class");
      if (submitButton.getAttribute("disabled") == "true") return;
      if (s !== 3) return;
      submitIcon.setAttribute("class", `${classData} fa-spin`);
      submitButton.setAttribute("disabled", "true");
      this.store
        .dispatch(Actions.DOWNLOAD, data)
        .then((response) => {
          submitIcon.setAttribute("class", `${classData}`);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          /*response.config.url.substr(
                response.config.url.lastIndexOf("/") + 1
              ) + ".zip";*/
          link.click();
          submitButton.removeAttribute("disabled");
        })
        .catch(() => {
          submitIcon.setAttribute("class", `${classData}`);
          Swal.fire({
            text: this.store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButton.removeAttribute("disabled");
        });
    },
    handleButtonClick: function (e: Event, { packetId, s, shippingMethod }) {
      if (e == null || e.target == null) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      // !!! order is important !!!
      let data:
        | { translation: string; packetId: number }
        | { packetIds: number[] } = {
        translation: this.gettext.current ?? "en",
        packetId,
      };
      if (submitButton.getAttribute("disabled")) return;
      if (s === 2) {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        this.store
          .dispatch(Actions.ACCEPT, data)
          .then(() => {
            this.store
              .dispatch(Actions.DOWNLOAD, data)
              .then((response) => {
                submitButton.removeAttribute("data-kt-indicator");
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                const splited =
                  response.headers["content-disposition"].split("=");
                link.download = decodeURIComponent(
                  splited[splited.length - 1].replaceAll('"', "")
                );
                response.config.url.substr(
                  response.config.url.lastIndexOf("/") + 1
                ) + ".zip";
                link.click();
                submitButton.removeAttribute("disabled");
              })
              .catch(() => {
                submitButton.removeAttribute("data-kt-indicator");
                Swal.fire({
                  text: this.store.getters.getErrors,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: this.$gettext("Try again!"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
                submitButton.removeAttribute("disabled");
              });
          })
          .catch(() => {
            submitButton.removeAttribute("data-kt-indicator");
            Swal.fire({
              text:
                this.store.getters.getErrors === "generic"
                  ? "Contact support via email"
                  : this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: this.$gettext("Contact support"),
              cancelButtonText: this.$gettext("Cancel"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
                cancelButton: "btn fw-bold btn-light-danger",
              },
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                document.location.href =
                  "mailto:support@printeepro.com?subject=" +
                  encodeURIComponent(`WEBSPS - ${packetId} - ERROR`) +
                  "&body=" +
                  encodeURIComponent(
                    `ERROR has occurred when calling packet ${packetId}.\n\n<i>For easier error debuging, please write steps to replicate error below.</i>`
                  );
              }
            });
            submitButton.removeAttribute("disabled");
          });
      } else if ((s === 3 || s === 4) && shippingMethod !== "local_pickup") {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;

        data = {
          packetIds: [packetId],
        };

        this.store
          .dispatch(Actions.LABELS, data)
          .then((response) => {
            submitButton.removeAttribute("data-kt-indicator");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const splited = response.headers["content-disposition"].split("=");
            link.download = decodeURIComponent(
              splited[splited.length - 1].replaceAll('"', "")
            );
            link.click();
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            submitButton.removeAttribute("data-kt-indicator");
            console.log(this.store.getters.getErrors);
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton.removeAttribute("disabled");
          });
        /*} else if (s === 4) {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;

        this.store
          .dispatch(Actions.PICKUP, data)
          .then(() => {
            submitButton.removeAttribute("data-kt-indicator");
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });*/
      } else if (s === 3 && shippingMethod === "local_pickup") {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;
        this.store
          .dispatch(Actions.FINISH, data)
          .then(() => {
            submitButton.removeAttribute("data-kt-indicator");
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            submitButton.removeAttribute("data-kt-indicator");
            console.log(this.store.getters.getErrors);
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton.removeAttribute("disabled");
          });
      } else if (s === 4 && shippingMethod === "local_pickup") {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;
        this.store
          .dispatch(Actions.PICKUP, data)
          .then(() => {
            submitButton.removeAttribute("data-kt-indicator");
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    },
  },
});
