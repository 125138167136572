<template>
  <!--begin::Tables Widget 14-->
  <div :class="widgetClasses" class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">{{
          $gettext("Printee orders")
        }}</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{
          $gettext("List of all new & unfinished orders")
        }}</span>
      </h3>
      <div class="card-toolbar">
        <a
          class="btn btn-info font-weight-bolder font-size-sm me-3 card-toolbar"
          @click="printAllButtonClick($event)"
        >
          <span class="indicator-label">
            {{ $gettext("Print selected") }}
          </span>
          <span class="indicator-progress"
            >{{ $gettext("Loading") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </a>
        <a
          class="btn btn-danger font-weight-bolder font-size-sm me-3 card-toolbar"
          @click="acceptAllButtonClick($event)"
        >
          <span class="indicator-label">
            {{ $gettext("Accept selected") }}
          </span>
          <span class="indicator-progress"
            >{{ $gettext("Loading") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </a>
        <a
          class="btn btn-light font-weight-bolder font-size-sm card-toolbar"
          @click="downloadAllButtonClick($event)"
        >
          <span class="indicator-label">
            {{ $gettext("Download selected") }}
          </span>
          <span class="indicator-progress"
            >{{ $gettext("Loading") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </a>
      </div>
      <!--
      <div class="card-toolbar">
        //begin::Menu
        <button
          type="button"
          class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg
              src="media/icons/duotone/Layout/Layout-4-blocks-2.svg"
            />
          </span>
        </button>
        <Dropdown2></Dropdown2>
        //end::Menu
      </div>
      -->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="table table-row-bordered table-row-gray-400 align-top gs-0 gy-3"
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="w-25px"></th>
              <th class="">{{ $gettext("PACKET ID") }}</th>
              <th class="">{{ $gettext("PRODUCT") }}</th>
              <th class="">{{ $gettext("PAYMENT") }}</th>
              <th class="">{{ $gettext("CUSTOMER") }}</th>
              <th class="">{{ $gettext("SUBMITED") }}</th>
              <th class="">{{ $gettext("STATUS") }}</th>
              <th class="text-center">{{ $gettext("ACTION") }}</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template
              v-for="(
                item, index
              ) in store.state.PacketsModule.orderList.printeePackets.filter(
                (el) => {
                  if (!store.state.PacketsModule.showFinished) {
                    return (
                      el.s < 4 ||
                      (el.s < 5 && el.shippingMethod == 'local_pickup')
                    );
                  }
                  return true;
                }
              )"
              :key="index"
            >
              <tr>
                <td>
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid"
                  >
                    <input
                      class="form-check-input widget-14-check"
                      type="checkbox"
                      :value="{ packetId: item.packetId }"
                      v-model="checkedPackets"
                    />
                  </div>
                </td>

                <td>
                  <div class="text-dark fw-bolder fs-6">
                    {{ item.packetId }}
                  </div>
                  <span class="text-muted fw-bold text-muted d-block fs-7">{{
                    item.customer.tracking
                  }}</span>
                </td>
                <td>
                  <template
                    v-for="(product, jndex) in item.products"
                    :key="jndex"
                  >
                    <div class="text-dark fw-bolder d-block mb-1 fs-6">
                      {{ dynStr(product.product, "title") }}
                    </div>
                    <span class="text-muted fw-bold text-muted d-block fs-7"
                      >{{ dynStr(product.format, "title") }} -
                      {{
                        $ngettext(
                          "%{ count } pc",
                          "%{ count } pcs",
                          product.count,
                          { count: product.count }
                        )
                      }}</span
                    >
                  </template>
                </td>

                <td>
                  <template
                    v-for="(payment, jndex) in item.payments"
                    :key="jndex"
                  >
                    <div class="text-dark fw-bolder d-block mb-1 fs-6">
                      {{
                        payment.type === "on_delivery"
                          ? $gettext("Cash on pickup")
                          : payment.type === "card"
                          ? $gettext("Credit card")
                          : payment.type === "paypal"
                          ? $gettext("PayPal")
                          : $gettext(payment.type)
                      }}
                    </div>
                    <span class="text-muted fw-bold text-muted d-block fs-7">{{
                      payment.displayAmount
                    }}</span>
                  </template>
                </td>

                <td>
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <span class="symbol-label bg-light">
                        <img
                          :src="item.customer.image"
                          class="h-75 align-self-end"
                          alt=""
                        />
                      </span>
                    </div>
                    <div class="d-flex justify-content-start flex-column">
                      <div class="text-dark fw-bolder d-block mb-1 fs-6">
                        {{ item.customer.name }} {{ item.customer.lastname }}
                      </div>
                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.customer.phone }}</span
                      >
                    </div>
                  </div>
                </td>
                <td class="fs-6">
                  <div
                    class="card-toolbar"
                    data-bs-delay-show="1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :title="`${item.datetime.time}`"
                  >
                    <span
                      :class="`text-${item.datetime.color}`"
                      class="fw-bolder d-block mb-1 fs-6"
                      >{{ item.datetime.date }}</span
                    >
                    <span
                      :class="`text-${item.datetime.color}`"
                      class="fw-bold d-block fs-7"
                      >{{ item.datetime.timeRemaining }}</span
                    >
                  </div>
                </td>

                <td>
                  <span
                    :class="`badge-light-${item.status.color}`"
                    class="badge"
                    >{{
                      item.s > 2
                        ? item.s > 3
                          ? item.s == 4
                            ? $gettext("Finished")
                            : $gettext("Picked up")
                          : $gettext("In progress")
                        : $gettext("New order")
                    }}</span
                  >
                  <a
                    v-if="item.s === 3"
                    @click="downloadButtonClick($event, item)"
                    class="cursor-pointer"
                  >
                    <span class="indicator-label">
                      <i class="fas fa-solid fa-download ms-2 fs-1"></i>
                    </span>

                    <span class="indicator-progress"
                      ><span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span>
                    </span>
                  </a>
                </td>
                <td class="text-end">
                  <a
                    v-if="item.s != 5"
                    @click="handleButtonClick($event, item)"
                    class="btn font-weight-bolder font size-sm w-100"
                    :class="`btn-${item.action.color}`"
                  >
                    <span
                      v-if="item.shippingMethod !== 'local_pickup'"
                      class="indicator-label"
                      >{{
                        item.s > 2
                          ? item.s > 3
                            ? item.s == 4
                              ? $gettext("Print")
                              : $gettext("Hide")
                            : $gettext("Finish")
                          : $gettext("Accept")
                      }}</span
                    >
                    <span
                      v-if="item.shippingMethod === 'local_pickup'"
                      class="indicator-label"
                      >{{
                        item.s > 2
                          ? item.s > 3
                            ? item.s == 4
                              ? $gettext("Picked up")
                              : $gettext("Hide")
                            : $gettext("Finish")
                          : $gettext("Accept")
                      }}</span
                    >
                    <span class="indicator-progress"
                      >{{ $gettext("Loading") }}
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span>
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 14-->
  <!---begin::Modals-->
  <div class="modal fade" tabindex="-1" id="gls_input_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $gettext("GLS shipping info: ") }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-danger ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
      </div>
    </div>
  </div>
  <!---end::Modals-->
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useGettext } from "vue3-gettext";
import { dynStr } from "@/core/services/DynamicTranslationService";

export default defineComponent({
  name: "kt-widget-14",
  components: {
    //Dropdown2
  },
  props: {
    widgetClasses: String,
  },
  data() {
    const store = useStore();
    let ws3;
    const gettext = useGettext();
    const checkedPackets: { packetId: string | null }[] = [];
    return {
      checkedPackets,
      ws3,
      store,
      dynStr,
      gettext,
    };
  },
  methods: {
    printAllButtonClick: function (e: Event) {
      if (e == null || e.target == null) return;
      if (this.checkedPackets.length < 1) return;
      const packetIds: string[] = [];
      for (const pac of this.checkedPackets) {
        if (pac["packetId"] == null) continue;
        const packet =
          this.store.state.PacketsModule.orderList.printeePackets.find(
            (i) => i.packetId == pac["packetId"]
          );
        if (packet.s == 3 || packet.s == 4) {
          packetIds.push(pac["packetId"]);
        }
      }
      if (packetIds.length < 1) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      const data = {
        packetIds,
      };
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");
      this.store
        .dispatch(Actions.LABELS, data)
        .then((response) => {
          submitButton.removeAttribute("data-kt-indicator");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          link.click();
          submitButton.removeAttribute("disabled");
        })
        .catch(() => {
          submitButton.removeAttribute("data-kt-indicator");
          console.log(this.store.getters.getErrors);
          Swal.fire({
            text: this.store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButton.removeAttribute("disabled");
        });
    },
    acceptAllButtonClick: function (e: Event) {
      if (e == null || e.target == null) return;
      if (this.checkedPackets.length < 1) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      if (submitButton.getAttribute("disabled")) return;
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");
      for (const pac of this.checkedPackets) {
        if (pac["packetId"] == null) continue;
        console.log(pac);
        const packet =
          this.store.state.PacketsModule.orderList.printeePackets.find(
            (i) => i.packetId == pac["packetId"]
          );
        let data:
          | { translation: string; packetId: string }
          | { packetIds: string[] } = {
          translation: this.gettext.current ?? "en",
          packetId: pac["packetId"],
        };
        if (packet.s === 2) {
          submitButton.setAttribute("data-kt-indicator", "on");
          submitButton.setAttribute("disabled", "true");
          this.store
            .dispatch(Actions.ACCEPT, data)
            .then(() => {
              this.store
                .dispatch(Actions.DOWNLOAD, data)
                .then((response) => {
                  submitButton.removeAttribute("data-kt-indicator");
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  const splited =
                    response.headers["content-disposition"].split("=");
                  link.download = decodeURIComponent(
                    splited[splited.length - 1].replaceAll('"', "")
                  );
                  response.config.url.substr(
                    response.config.url.lastIndexOf("/") + 1
                  ) + ".zip";
                  link.click();
                  submitButton.removeAttribute("disabled");
                })
                .catch(() => {
                  submitButton.removeAttribute("data-kt-indicator");
                  Swal.fire({
                    text: this.store.getters.getErrors,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: this.$gettext("Try again!"),
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-danger",
                    },
                  });
                  submitButton.removeAttribute("disabled");
                });
            })
            .catch(() => {
              submitButton.removeAttribute("data-kt-indicator");
              Swal.fire({
                text:
                  this.store.getters.getErrors === "generic"
                    ? "Contact support via email"
                    : this.store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: this.$gettext("Contact support"),
                cancelButtonText: this.$gettext("Cancel"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                  cancelButton: "btn fw-bold btn-light-danger",
                },
              }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                  document.location.href =
                    "mailto:support@printeepro.com?subject=" +
                    encodeURIComponent(`WEBSPS - ${pac["packetId"]} - ERROR`) +
                    "&body=" +
                    encodeURIComponent(
                      `ERROR has occurred when calling packet ${pac["packetId"]}.\n\n<i>For easier error debuging, please write steps to replicate error below.</i>`
                    );
                }
              });
              submitButton.removeAttribute("disabled");
            });
        } else if (packet.s === 3 || packet.s === 4) {
          submitButton.setAttribute("data-kt-indicator", "on");
          submitButton.setAttribute("disabled", "true");
          const token = getToken(ID_TOKEN_KEY);
          if (token == null || typeof token != "string") return;

          data = {
            packetIds: [pac["packetId"]],
          };

          this.store
            .dispatch(Actions.LABELS, data)
            .then((response) => {
              submitButton.removeAttribute("data-kt-indicator");
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              const splited =
                response.headers["content-disposition"].split("=");
              link.download = decodeURIComponent(
                splited[splited.length - 1].replaceAll('"', "")
              );
              link.click();
              submitButton.removeAttribute("disabled");
            })
            .catch(() => {
              submitButton.removeAttribute("data-kt-indicator");
              console.log(this.store.getters.getErrors);
              Swal.fire({
                text: this.store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: this.$gettext("Try again!"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton.removeAttribute("disabled");
            });
        }
      }
      submitButton.removeAttribute("data-kt-indicator");
      submitButton.removeAttribute("disabled");
    },
    downloadAllButtonClick: function (e: Event) {
      if (e == null || e.target == null) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      if (this.checkedPackets.length < 1) return;
      const packetIds: string[] = [];
      for (const pac of this.checkedPackets) {
        if (pac["packetId"] == null) continue;
        console.log(pac);
        const packet =
          this.store.state.PacketsModule.orderList.printeePackets.find(
            (i) => i.packetId == pac["packetId"]
          );
        if (packet.s == 3) {
          packetIds.push(pac["packetId"]);
        }
      }
      if (packetIds.length < 1) return;
      const data = {
        translation: this.gettext.current ?? "en",
        packetIds: JSON.stringify(packetIds),
      };
      console.log(data);
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");
      this.store
        .dispatch(Actions.DOWNLOAD, data)
        .then((response) => {
          submitButton.removeAttribute("data-kt-indicator");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          response.config.url.substr(response.config.url.lastIndexOf("/") + 1) +
            ".zip";
          link.click();
          submitButton.removeAttribute("disabled");
        })
        .catch(() => {
          submitButton.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: this.store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButton.removeAttribute("disabled");
        });
    },
    downloadButtonClick: function (e: Event, { packetId, s }) {
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      const submitIcon = (
        (e.target as HTMLElement).tagName == "I"
          ? e.target
          : (e.target as HTMLElement).firstChild
      ) as HTMLElement;
      const data = {
        lang: this.gettext.current ?? "en",
        packetIds: JSON.stringify([packetId]),
      };
      const classData = submitIcon.getAttribute("class");
      if (submitButton.getAttribute("disabled") == "true") return;
      if (s !== 3) return;
      submitIcon.setAttribute("class", `${classData} fa-spin`);
      submitButton.setAttribute("disabled", "true");
      this.store
        .dispatch(Actions.DOWNLOAD, data)
        .then((response) => {
          submitIcon.setAttribute("class", `${classData}`);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const splited = response.headers["content-disposition"].split("=");
          link.download = decodeURIComponent(
            splited[splited.length - 1].replaceAll('"', "")
          );
          /*response.config.url.substr(
                response.config.url.lastIndexOf("/") + 1
              ) + ".zip";*/
          link.click();
          submitButton.removeAttribute("disabled");
        })
        .catch(() => {
          submitIcon.setAttribute("class", `${classData}`);
          Swal.fire({
            text: this.store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButton.removeAttribute("disabled");
        });
    },
    handleButtonClick: function (e: Event, { packetId, s, shippingMethod }) {
      if (e == null || e.target == null) return;
      const submitButton = (
        (e.target as HTMLElement).tagName == "A"
          ? e.target
          : (e.target as HTMLElement).parentNode
      ) as HTMLElement;
      // !!! order is important !!!
      let data:
        | { translation: string; packetId: number }
        | { packetIds: number[] } = {
        translation: this.gettext.current ?? "en",
        packetId,
      };
      if (submitButton.getAttribute("disabled")) return;
      if (s === 2) {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        this.store
          .dispatch(Actions.ACCEPT, data)
          .then(() => {
            this.store
              .dispatch(Actions.DOWNLOAD, data)
              .then((response) => {
                submitButton.removeAttribute("data-kt-indicator");
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                const splited =
                  response.headers["content-disposition"].split("=");
                link.download = decodeURIComponent(
                  splited[splited.length - 1].replaceAll('"', "")
                );
                response.config.url.substr(
                  response.config.url.lastIndexOf("/") + 1
                ) + ".zip";
                link.click();
                submitButton.removeAttribute("disabled");
              })
              .catch(() => {
                submitButton.removeAttribute("data-kt-indicator");
                Swal.fire({
                  text: this.store.getters.getErrors,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: this.$gettext("Try again!"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
                submitButton.removeAttribute("disabled");
              });
          })
          .catch(() => {
            submitButton.removeAttribute("data-kt-indicator");
            Swal.fire({
              text:
                this.store.getters.getErrors === "generic"
                  ? "Contact support via email"
                  : this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: this.$gettext("Contact support"),
              cancelButtonText: this.$gettext("Cancel"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
                cancelButton: "btn fw-bold btn-light-danger",
              },
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                document.location.href =
                  "mailto:support@printeepro.com?subject=" +
                  encodeURIComponent(`WEBSPS - ${packetId} - ERROR`) +
                  "&body=" +
                  encodeURIComponent(
                    `ERROR has occurred when calling packet ${packetId}.\n\n<i>For easier error debuging, please write steps to replicate error below.</i>`
                  );
              }
            });
            submitButton.removeAttribute("disabled");
          });
      } else if ((s === 3 || s === 4) && shippingMethod !== "local_pickup") {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;

        data = {
          packetIds: [packetId],
        };

        this.store
          .dispatch(Actions.LABELS, data)
          .then((response) => {
            submitButton.removeAttribute("data-kt-indicator");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const splited = response.headers["content-disposition"].split("=");
            link.download = decodeURIComponent(
              splited[splited.length - 1].replaceAll('"', "")
            );
            link.click();
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            submitButton.removeAttribute("data-kt-indicator");
            console.log(this.store.getters.getErrors);
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton.removeAttribute("disabled");
          });
        /*} else if (s === 4) {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;

        this.store
          .dispatch(Actions.PICKUP, data)
          .then(() => {
            submitButton.removeAttribute("data-kt-indicator");
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });*/
      } else if (s === 3 && shippingMethod === "local_pickup") {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;
        this.store
          .dispatch(Actions.FINISH, data)
          .then(() => {
            submitButton.removeAttribute("data-kt-indicator");
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            submitButton.removeAttribute("data-kt-indicator");
            console.log(this.store.getters.getErrors);
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton.removeAttribute("disabled");
          });
      } else if (s === 4 && shippingMethod === "local_pickup") {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "true");
        const token = getToken(ID_TOKEN_KEY);
        if (token == null || typeof token != "string") return;
        this.store
          .dispatch(Actions.PICKUP, data)
          .then(() => {
            submitButton.removeAttribute("data-kt-indicator");
            submitButton.removeAttribute("disabled");
          })
          .catch(() => {
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: this.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    },
  },
});
</script>
